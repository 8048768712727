import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import ModularBlocks from '../components/ModularBlocks';

const DefaultPageTemplate = ({
  data: { datoCmsPage },
  pageContext: { locale },
}) => {
  const {
    seoMetaTags,
    noIndex,
    slugLocales,
    title,
    subtitle,
    verticalText,
    bannerOverline,
    bannerLinkPage,
    bannerLinkText,
    bannerImage,
    modularBlocks,
    subpages,
  } = datoCmsPage;

  return (
    <Layout
      seo={seoMetaTags}
      noIndex={noIndex}
      locale={locale}
      slugLocales={slugLocales}
      page={datoCmsPage}
      verticalText={verticalText || title}
    >
      <main>
        <Banner
          overline={bannerOverline}
          heading={title}
          text={subtitle}
          link={{
            page: bannerLinkPage,
            text: bannerLinkText,
          }}
          image={bannerImage}
          locale={locale}
        />
        <ModularBlocks
          items={modularBlocks}
          subpages={subpages.sort((a, b) => a.position - b.position)}
          locale={locale}
        />
      </main>
    </Layout>
  );
};

export const DefaultPageTemplateQuery = graphql`
  query DefaultPageTemplateQuery($id: String!) {
    datoCmsPage(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      noIndex
      slugLocales: _allSlugLocales {
        locale
        value
      }
      title
      subtitle
      verticalText
      bannerOverline
      bannerLinkPage {
        ...LinkFragment
      }
      bannerLinkText
      bannerImage {
        ...SubpageBannerImageFragment
      }
      modularBlocks {
        ...AccordionModularBlockFragment
        ...BlockquoteModularBlockFragment
        ...CallbackCtaModularBlockFragment
        ...ContainedImageModularBlockFragment
        ...ContentFeaturedPointsModularBlockFragment
        ...ContentListModularBlockFragment
        ...ContentModularBlockFragment
        ...ExternalVideoModularBlockFragment
        ...FeaturedCaseStudyModularBlockFragment
        ...FeaturedLogosModularBlockFragment
        ...FeaturedPointsModularBlockFragment
        ...FeaturedSystemsModularBlockFragment
        ...FullWidthImageModularBlockFragment
        ...ImageContentModularBlockV1Fragment
        ...ImageContentModularBlockV2Fragment
        ...ImageFeaturedPointsModularBlockFragment
        ...ImageSliderModularBlockFragment
        ...InternalVideoModularBlockFragment
        ...LatestNewsModularBlockFragment
        ...LiquidChemicalsModularBlockFragment
        ...MediaContentListModularBlockFragment
        ...MixerCategoriesModularBlockFragment
        ...PageImageCardsModularBlockFragment
        ...PageTextCardsModularBlockFragment
        ...QuoteCtaModularBlockFragment
        ...SubpageTextCardsModularBlockFragment
        ...TabsModularBlockFragment
        ...TestimonialsModularBlockFragment
      }
      subpages: treeChildren {
        ...PageTextCardFragment
      }
      ...LinkFragment
    }
  }
`;

export default DefaultPageTemplate;
